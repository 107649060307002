<template>
  <Teleport to="body">
    <LazyRaeppliRaege v-if="shouldShow" @done="shouldShow = false" />
  </Teleport>
</template>

<script lang="ts" setup>
const route = useRoute()
const isHome = computed(() => !!route.name?.toString().startsWith('home'))

const raeppliCookie = useCookie('has_shown_raeppli', {
  default: () => false,
  sameSite: true,
  maxAge: 3 * 60 * 60,
})

const isFasnachtToday = computed(() => {
  function getEasterDate(year: number) {
    // Computus algorithm to calculate Easter Sunday
    const a = year % 19
    const b = Math.floor(year / 100)
    const c = year % 100
    const d = Math.floor(b / 4)
    const e = b % 4
    const f = Math.floor((b + 8) / 25)
    const g = Math.floor((b - f + 1) / 3)
    const h = (19 * a + b - d - g + 15) % 30
    const i = Math.floor(c / 4)
    const k = c % 4
    const l = (32 + 2 * e + 2 * i - h - k) % 7
    const m = Math.floor((a + 11 * h + 22 * l) / 451)
    const month = Math.floor((h + l - 7 * m + 114) / 31)
    const day = ((h + l - 7 * m + 114) % 31) + 1
    return new Date(year, month - 1, day)
  }

  function getFasnachtDates(year: number) {
    const easter = getEasterDate(year)
    const ashWednesday = new Date(easter)
    ashWednesday.setDate(easter.getDate() - 46)
    const fasnachtStart = new Date(ashWednesday)
    fasnachtStart.setDate(ashWednesday.getDate() + 5) // Monday after Ash Wednesday
    fasnachtStart.setHours(4, 0, 0, 0)

    const fasnachtEnd = new Date(fasnachtStart)
    fasnachtEnd.setDate(fasnachtStart.getDate() + 3) // 72 hours later (Thursday)
    fasnachtEnd.setHours(4, 0, 0, 0)

    return { start: fasnachtStart, end: fasnachtEnd }
  }

  const today = new Date()
  const { start, end } = getFasnachtDates(today.getFullYear())

  return today >= start && today < end
})

const hasRaeppli = computed(() => {
  return isFasnachtToday.value && isHome.value && !raeppliCookie.value
})

watch(
  () => hasRaeppli.value,
  (newValue: boolean) => {
    if (newValue) {
      shouldShow.value = newValue
      raeppliCookie.value = true
    }
  },
)

const shouldShow = ref(false)

onMounted(() => {
  shouldShow.value = hasRaeppli.value
  if (shouldShow.value) {
    raeppliCookie.value = true
  }
})
</script>
