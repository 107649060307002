<template>
  <NuxtPageDependency>
    <h1 v-if="links.length > 0" class="sr-only">
      {{ $texts('srLandmarks.breadcrumbNavigation', 'Breadcrumb-Navigation') }}
    </h1>

    <Transition :duration="300" name="breadcrumb-list-show-hide">
      <nav
        v-if="links.length > 0"
        class="bg-gray-20 border-b border-b-gray-400 -mt-1 relative print:hidden"
        :style="{
          '--breadcrumb-item-height': (links.length > 50 ? 70 : 54) + 'px',
          '--breadcrumb-total-height': breadcrumbHeight,
        }"
        :aria-label="$texts('breadcrumbs.breadcrumb', 'Breadcrumb')"
      >
        <div class="container relative">
          <TransitionGroup
            tag="ol"
            itemscope
            itemtype="http://schema.org/BreadcrumbList"
            class="relative transition-all overflow-hidden h-[var(--breadcrumb-total-height)]"
            enter-active-class="duration-300"
            leave-active-class="duration-300"
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
          >
            <li
              v-for="(link, index) in links"
              :key="link.title + index"
              itemprop="itemListElement"
              itemscope
              itemtype="http://schema.org/ListItem"
              class="breadcrumb-item relative overflow-hidden"
            >
              <component
                :is="link.tag"
                v-bind="link.props"
                itemprop="item"
                class="breadcrumb-link block py-15 font-medium breadcrumb-caret before:top-0 border-t border-t-gray-400 text-blue-900 hover:text-blue-700 overflow-hidden text-ellipsis"
              >
                <span itemprop="name" class="whitespace-nowrap">
                  {{ link.title }}
                </span>
              </component>
              <meta itemprop="position" :content="(index + 1).toString()" />
            </li>
          </TransitionGroup>
        </div>
      </nav>
    </Transition>

    <div class="container">
      <hr
        class="breadcrumb-caret relative h-0 border-none overflow-visible -top-1"
      />
    </div>
  </NuxtPageDependency>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components'

const breadcrumbLinks = useBreadcrumbLinks()

const links = computed(() => {
  return breadcrumbLinks.value
    .filter((l) => !l.isHidden)
    .slice(0, -1)
    .map((link) => {
      const { title, url } = link
      const to = url?.path
      return {
        tag: to ? NuxtLink : 'span',
        props: to ? { to } : {},
        title,
      }
    })
})

const host = useRequestURL().host

const jsonLdBreadcrumbLinks = computed(() => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbLinks.value.map((link, i) => {
      const { title, url } = link
      const to = url?.path

      return {
        '@type': 'ListItem',
        position: i + 1,
        item: {
          '@id': 'https://' + host + to,
          name: title,
        },
      }
    }),
  }
})

const breadcrumbHeight = computed(
  () => `calc(var(--breadcrumb-item-height) * ${links.value.length})`,
)

useHead(() => {
  return {
    script: [
      {
        type: 'application/ld+json',
        textContent: JSON.stringify(jsonLdBreadcrumbLinks.value),
      },
    ],
  }
})
</script>

<style lang="postcss">
.breadcrumb-caret:before {
  @apply w-0 h-0 content-[''] left-15 absolute border-x-[7px] border-t-[6px] border-t-gray-400 border-x-transparent border-solid;
}

.breadcrumb-item {
  height: var(--breadcrumb-item-height);
}

.breadcrumb-item:hover ~ .breadcrumb-item .breadcrumb-link {
  @apply text-gray-300;
}

@media (prefers-reduced-motion) {
  .breadcrumbs * {
    @apply !transition-none;
  }
}

.breadcrumb-list-show-hide-leave-active ol {
  @apply !h-0;
}

.breadcrumb-list-show-hide-enter-active ol {
  @apply !h-0;
}
.breadcrumb-list-show-hide-enter-to ol {
  @apply !h-[var(--breadcrumb-total-height)];
}
</style>
